<!--
 * @Author: yuwenwen
 * @Date: 2022-11-25 11:13:51
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-12-26 17:39:47
 * @FilePath: \nc-funeral-mobile\src\views\search\search.vue
-->
<!-- 搜索页面 -->
<template>
  <div class="page-container page-container-100 complaint">
    <div class="search-container">
      <img src="../../assets/images/search-icon.png" />
      <van-field
        v-model="searchCon.advTitle"
        placeholder="搜索政策资讯关键字... "
        @keypress.enter="search"
      />
    </div>
    <van-list v-model="paginationList.loading"
      :finished="paginationList.finished"
      finished-text="没有更多了"
      @load="onLoad"
      error-text="加载失败,点击重新加载">
      <div
        v-for="(item, index) in paginationList.list"
        :key="index"
        @click="toDetails(item)"
      >
        <div class="complaint-datetime">{{ item.publishDate }}</div>
        <div class="policy-box">
          <img class="img" :src="item.images" />
          <div class="remark">
            {{ item.advTitle }}
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import { getPolicyList } from "@/api/index.js";
import { getImagesUrl } from "@/utils/funeral.js";
export default {
  name: "Search",
  data() {
    return {
      policyList: [],
      searchCon: {
        publishMode: 0,
        advTitle: "",
        pageNo: 1,
        pageSize: 10,
      },

      paginationList: {
        total: 0, //总共多少条
        list: [], //本地list
        loading: false,
        finished: false,
        error: false,
      },
    };
  },
  created() {
    let { publishMode, keyword } = this.$route.query;
    this.searchCon.publishMode = publishMode;
    this.searchCon.advTitle = keyword;
  },
  mounted() {},
  methods: {
    // 搜索
    search(){
      this.paginationList.list = [];
      this.searchCon.pageNo = 1;
      this.getPolicyList()
      this.$router.replace({path:'/search/search',query:{keyword:this.searchCon.advTitle}});
    },
    // 获取政策列表
    async getPolicyList() {
      let plyList = this.paginationList.list;
      let [err, res] = await this.$awaitWrap(getPolicyList(this.searchCon));
      if (err) {
        return;
      }
      let data = res.result;
      if (data && data.records.length > 0) {
        console.log(data.records);
        for (let i = 0; i < data.records.length; i++) {
          if(data.records[i].images){
            data.records[i].images = getImagesUrl(data.records[i].images);
          }
          let newObj = {
            ...data.records[i],
          };
          plyList.push(newObj);
        }
        this.paginationList.list = plyList;
        this.paginationList.total = data.total;
        this.paginationList.loading = false;
        if (this.paginationList.list.length >= data.total) {
          this.paginationList.finished = true; // 结束加载状态
        }
      } else {
        this.paginationList.finished = true;
      }
    },

    onLoad() {
      let timer = setTimeout(() => {
        if (this.paginationList.total == 0) {
          this.getPolicyList();
          this.searchCon.pageNo = this.searchCon.pageNo  + 1;
          this.paginationList.finished && clearTimeout(timer);
        } else {
          let list = this.paginationList.list;
          let total = this.paginationList.total;
          if (list.length < total) {
            this.getPolicyList();
            this.searchCon.pageNo  = this.searchCon.pageNo  + 1;
            this.paginationList.finished && clearTimeout(timer);
          }
        }
      }, 100);
    },

    // 详情
    toDetails(item) {
      this.$router.push({ path: "/policy/details", query: { id: item.id } });
    },
  },
};
</script>
<style lang="less" scoped>
.van-cell {
  padding: 0 !important;
}
.search-container {
  width: 333px;
  height: 29px;
  background: #ffffff;
  border-radius: 15px;
  margin: 15px auto;
  margin-bottom: 0;
  padding: 0 10px;
  display: flex;
  align-items: center;
  img {
    width: 11px;
    height: 11px;
    margin-right: 7px;
  }
}
@import "../../assets/styles/index.less";
</style>